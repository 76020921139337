import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { OddsDetail } from "../../redux/slices/dataTypes/newTypes/OddType";
import './OddsIndicator.style.css';

const OddsIndicator = ({ oddObject }: { oddObject: OddsDetail }) => {
  if (!oddObject?.previousValue) {
    return null;
  }

  return (
    <>
      {oddObject.Odd > oddObject.previousValue && (
        <ArrowDropUpIcon
          style={{
            color: "green",
            width: "15px",
            animation: "blink 1s step-end 20",
          }}
        />
      )}
      {oddObject.Odd < oddObject.previousValue && (
        <ArrowDropDownIcon
          style={{
            color: "red",
            width: "15px",
            animation: "blink 1s step-end 20",
          }}
        />
      )}
    </>
  );
};

export default OddsIndicator;
