// App.tsx
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Sidebar from './components/sidebar/sidebar';
import Home from './components/Home/Home';
import styles from './theme.module.css';
import DetailContainer from './components/DetailContainer/DetailContainer';
import DepositContainer from './components/DepositContainer/DepositContainer';
import WinContainer from './components/WinContainer/WinContainer';
import InternetPayout from './components/InternetPayout/InternetPayout';
import NewClientAccount from './components/NewClienttAccount/NewClientAccount';
import AccountReport from './components/AccountReport/AccountReport';
import Login from './components/Login/Login';
import useSessionManagement from './components/Session/useSessionManagement';
import ConfirmRegistration from './components/Registration/ConfirmRegistration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword/ResetPassword';
import SetNewPassword from './components/ForgotPassword/ForgotPasswordEmailConformation/ForgotPasswordEmailConformation';
import SignUpOverlay from './components/Overlay/SignupOverlay/SignupOverlay';
import {useAppDispatch} from './redux/hooks';
import { fetchAllCompetitions, fetchDifferentSports } from './redux/thunk/publicDataThunk';
import { setSportNameAndId } from './redux/slices/publicDataSlices/SportNameSlice';
import { Socket } from "@flying-spoon/lib-socket-client";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { updateWithLiveOdds } from "./redux/slices/publicDataSlices/OddsSlice";

const Client = Socket.Client;

function App() {
    useSessionManagement();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const leagueId = useSelector((state: RootState) => state.competitionName.competition_id);

    useEffect(() => {
        dispatch(fetchDifferentSports());
        dispatch(fetchAllCompetitions());
        dispatch(setSportNameAndId({sport_name:'soccer',sport_id:1}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    
    useEffect(() => {
        if (!leagueId) return;
        const socketClient = new Client({
            url: `wss://websocket.sis.bet/odds/${leagueId}`,
        });
        // console.log(`Connecting WebSocket for leagueId: ${leagueId}`);

        const setupSocket = async () => {
        try {
            await socketClient.connect();
            console.log(`WebSocket connected for leagueId: ${leagueId}`);
            socketClient.subscribe("update", (payload: any) => {
            console.log(
                `Received update for leagueId ${leagueId},:`,
                payload
            );
            dispatch(updateWithLiveOdds(payload));
            });
        } catch (error) {
            console.error(
            `Error connecting WebSocket for leagueId: ${leagueId}`,
            error
            );
        }
        };

        setupSocket();

        // Cleanup on component unmount or leagueId change
        return () => {
        // console.log(`Disconnecting WebSocket for leagueId: ${leagueId}`);
        socketClient.disconnect().then(() => {
            console.log(`WebSocket disconnected for leagueId: ${leagueId}`);
        });
        };
    }, [dispatch, leagueId]);


    const excludedPaths = [
        "/register-confirm",
        "/forgot-password",
        "/reset-password",
        "/set-new-password",
        "/login",
        "/register"
    ];
    let showHeaderAndFooter = !excludedPaths.includes(location.pathname);
    return (

        <div className={styles.appContainer}>
            {showHeaderAndFooter && <Sidebar/>}
            <div className={styles.mainContent}>
                <Routes>
                    <Route path="/" element={< Navigate replace to = "/EnterBet" />}/>
                    <Route path="/EnterBet" element={< Home />}/>
                    <Route path="/Fixture/:id" element={< DetailContainer />}/>
                    <Route path="/deposit" element={< DepositContainer />}/>
                    <Route path="/win" element={< WinContainer />}/>
                    <Route path="/InternetPayout" element={< InternetPayout />}/>
                    <Route path="/NewClientAccount" element={< NewClientAccount />}/>
                    <Route path="/AccountReport" element={< AccountReport />}/>
                    <Route path="/Login" element={< Login />}/>
                    <Route path="register-confirm" element={< ConfirmRegistration />}/>
                    <Route path="forgot-password" element={< ForgotPassword />}/>
                    <Route path="set-new-password" element={< ResetPassword />}/>
                    <Route path="reset-password" element={< SetNewPassword />}/>
                    <Route path="register" element={< SignUpOverlay />}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
