import * as React from 'react';
import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import classNames from 'classnames';
import styles from './SingleStakeSummary.module.css';
// import { useAppDispatch } from '../../../../../redux/hooks';s
// import { BetSlip } from '../../../../../redux/slices/dataTypes/newTypes/BetSlipType';
// import { placeBet } from '../../../../../redux/thunk/dataThunk';
import { v4 as uuidv4 } from 'uuid';
import { BetSlip } from '../../../../redux/slices/dataTypes/newTypes/BetSlipType';
import { Fixture } from '../../../../redux/slices/dataTypes/newTypes/FixturesType';
import { Odd } from '../../../../redux/slices/dataTypes/newTypes/OddType';

export type BetEvents = {
    [group_id : string]:  string[]
};

export default function OutlinedCard({ onOpenModal, betsData}: { onOpenModal: (betType: string) => void, betsData: BetEvents }) {
    // const dispatch = useAppDispatch();

    const [stakePerBet, setStakePerBet] = React.useState<string>("5");
    const [isButtonDisabled, setButtonDisabled] = React.useState(false);
    const odds = useSelector((state: RootState)=> state.odds.odds);
    const isActive = useSelector((state: RootState) => state.active.finalActive);
    const authToken = useSelector((state: RootState) => state.session.session_token);
    const fixtures = useSelector((state: RootState) => state.fixtures.fixtures);

    const trueObjectIds = Object.keys(isActive).filter(id => isActive[id]);

    React.useEffect(() => {
        if (trueObjectIds.length === 0) {
            setButtonDisabled(true);
        }else{
            setButtonDisabled(false);
        }
        // if (!authToken || trueObjectIds.length === 0) {
        //     setButtonDisabled(true);
        // } else {
            // setButtonDisabled(false);
        // }
    }, [authToken, trueObjectIds.length]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStakePerBet(event.target.value);
    };

    const multiplyAndSumOdds = (odds: Odd[], objectIds: string[], multiplier: number): number => {
        let sum = 0;
        for(let objectId of objectIds){
            const oddObject = odds?.find((odd)=>odd.GreenLineId === objectId.split(',')[0])
            if(oddObject){
                sum += oddObject?.Odds[parseInt(objectId.split(',')[1])]?.Odd * multiplier;
            }
        }
        return sum;
    }


    const submitSingleBets = (odds: Odd[], objectIds: string[], multiplier: number) => {
        
        const betslips: BetSlip[] = [];
        for(let objectId of objectIds){
            let oddItem = odds.find((odd) => odd.GreenLineId === objectId.split(',')[0]);
            
            let oddIndex = objectId.split(',')[1];
            if(oddItem){
                let fixtureItem = fixtures.find((fixture:Fixture) =>fixture.FixtureId ===  oddItem?.FixtureId);

                if(fixtureItem){
                    const betObject: any = {
                        currency: "NAD",  
                        uid: uuidv4(),
                        stake:parseInt(stakePerBet),
                        total_odd: oddItem.Odds[parseInt(oddIndex)].Odd,
                        possible_win: parseInt(stakePerBet) * oddItem.Odds[parseInt(oddIndex)].Odd,
                        objects: [
                            {
                                id: oddItem.Odds[parseInt(oddIndex)].OddId.toString(),
                                odd: oddItem.Odds[parseInt(oddIndex)].Odd,
                                sport: fixtureItem.Sport,
                                completion: fixtureItem.Competition,
                                country: fixtureItem.Country,
                                participant_1: fixtureItem.Participant1,
                                participant_2: fixtureItem.Participant2,
                                market_periode: oddItem.MarketPeriod,
                                market_parameters: oddItem.MarketParameters,
                                super_odds_type: oddItem.SuperOddsType,
                                start_time: fixtureItem.StartTime,
                                price_name: oddItem.PriceNames[parseInt(oddIndex)]
                            }
                        ]
                    }
                    betslips.push(betObject);
                }
            }
        }
        onOpenModal('single');
        // dispatch(placeBet(betslips))
        // console.log("betslip is ",betslips)
    };


    return (
        <Box sx={{ minWidth: 275 }} style={{position:'relative'}}>
            <Card className={classNames(styles['bet-summary-main-container'])}>
                <CardContent>
                    <div className={classNames(styles['bet-summary-container'])}>
                        <div className={classNames(styles['bet-description'])}>
                            <h3 className={classNames(styles['bet-team'])}>Stake Per Bet</h3>
                            <div className={classNames(styles['bet-amount'])}>
                                <FormControl className='stake-input'>
                                    <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                                    <OutlinedInput
                                        onChange={handleChange}
                                        className={classNames(styles['stake-per-bet-input'])}
                                        id="outlined-adornment-amount"
                                        value={stakePerBet}
                                        startAdornment={< InputAdornment className={styles.inputAdornment} position = "start" > N$ </InputAdornment>}
                                        label=""/>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div style={{margin:'5px 0px'}}>
                        <span className={classNames(styles['potential-payment'])} >Total Stake:
                            <span className={classNames(styles['stake-amount'])} >{`  N$ ${parseInt(stakePerBet) * trueObjectIds.length} `}</span>
                        </span>
                    </div>
                    <div style={{margin:'5px 0px'}}>
                        <span className={classNames(styles['potential-payment'])} >Possible Win:
                            <span className={classNames(styles['payout-amount'])} >{`  N$ ${multiplyAndSumOdds(odds,trueObjectIds,parseInt(stakePerBet)).toFixed(2)}`}</span>
                        </span>
                    </div>
                    <div className='button-container'
                    // className={classNames(styles['button-container'])}
                    >
                        <button 
                            disabled={isButtonDisabled} 
                            className="addToBetslipButton"
                            // className={styles.addToBetslipButton}
                            onClick={()=>submitSingleBets(odds,trueObjectIds,parseInt(stakePerBet))}>
                            Place Bet
                        </button>
                    </div>
                </CardContent>
            </Card>
        </Box>
    );
}
