import axios from "axios";
import { AppDispatch } from "../store";
import { setFixtures } from "../slices/publicDataSlices/FixturesSlice";
import { setSports } from "../slices/publicDataSlices/DifferentSportsSlice";
import { setAllCompetitions } from "../slices/publicDataSlices/LeaguesByCountrySlice";
import { setOdds } from "../slices/publicDataSlices/OddsSlice";


const headers = {
    'accept': 'application/json',
    'X-Boox-API-Key': process.env.REACT_APP_BOXX_API_KEY_RED,
  };

  export const fetchDifferentSports = () => async(dispatch : AppDispatch) => {
    const url = `${process.env.REACT_APP_RED_API_BASE_URL}/sports`;
    try {
      const response = await axios.get(url, { headers });
      dispatch(setSports(response.data))
    } catch (error) {
      console.error('Error fetching match sports:', error);
    }
};

export const fetchAllCompetitions = () => async(dispatch : AppDispatch) => {
    const url = `${process.env.REACT_APP_RED_API_BASE_URL}/competitions`;
    
    try {
      const response = await axios.get(url, { headers });
      if(response){
        dispatch(setAllCompetitions(response.data));
  
      }
    } catch (error) {
      console.error('Error fetching Leagues:', error);
    }
  };


export const fetchSingleFixture = (FixtureId: number) => async(dispatch : AppDispatch) => {
    const url = `${process.env.REACT_APP_RED_API_BASE_URL}/fixtures`;
    const params = { filter: `{"FixtureId":${FixtureId}}` };
    try {
      const response = await axios.get(url, { params, headers });
      dispatch(setFixtures(response.data));
      console.log('fetchSingleFixture response',response.data);
    } catch (error) {
      console.error('Error fetching fixtures:', error);
    }
};

export const fetchFixtures = (CompetitionId: number) => async(dispatch : AppDispatch) => {
    const url = `${process.env.REACT_APP_RED_API_BASE_URL}/fixtures`;
    // console.log("fetching fixtures");
    const params = { filter: `{"CompetitionId":${CompetitionId}}` };
    try {
      const response = await axios.get(url, { params, headers });
      // console.log("fixtures are ",response.data);
      dispatch(setFixtures(response.data));
    } catch (error) {
      console.error('Error fetching fixtures:', error);
    }
};

export const fetchOddsForSingleFixture = (CompetitionId: number) => async(dispatch : AppDispatch) => {
  const url = `${process.env.REACT_APP_RED_API_BASE_URL}/greenline-tx-stable-price`;
  const params = { filter: `{ "FixtureId": ${CompetitionId} }` };

  try {
    const response = await axios.get(url, { params, headers });
    dispatch(setOdds(response.data));
    console.log('fetchOddsForSingleFixture response',response.data);
  } catch (error) {
    console.error('Error fetching match odds:', error);
  }
};

export const fetchOddsForCompetition = (CompetitionId: number) => async(dispatch : AppDispatch) => {
  const url = `${process.env.REACT_APP_RED_API_BASE_URL}/greenline-tx-stable-price`;
  const params = { filter: `{ "CompetitionId": ${CompetitionId} }` };

  try {
    const response = await axios.get(url, { params, headers });
    dispatch(setOdds(response.data));
  } catch (error) {
    console.error('Error fetching match odds:', error);
  }
};

