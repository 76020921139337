import { createSlice } from '@reduxjs/toolkit';
import { Odd } from '../dataTypes/newTypes/OddType';

interface OddsState {
    odds: Odd[];
    oddsByFixture: { [key: number]: Odd[] };
}

const initialState: OddsState = {
    odds: [],
    oddsByFixture: {}
};

export const oddsSlice = createSlice({
    name: 'odds',
    initialState,
    reducers: {
        setOdds: (state, action) => {
            const newOdds: Odd[] = action.payload.resource;

            newOdds.forEach(newOdd => {
                const existingOddIndex = state.odds.findIndex(odd => odd._id.toString() === newOdd._id.toString());

                if (existingOddIndex !== -1) {
                    // Updating existing odd
                    state.odds[existingOddIndex] = newOdd;
                } else {
                    // Adding new odd
                    state.odds.push(newOdd);
                }

                // Storing odds by FixtureId
                const fixtureId = newOdd.FixtureId;
                if (!state.oddsByFixture[fixtureId]) {
                    state.oddsByFixture[fixtureId] = [];
                }

                const fixtureOddIndex = state.oddsByFixture[fixtureId].findIndex(
                    odd => odd._id.toString() === newOdd._id.toString()
                );

                if (fixtureOddIndex !== -1) {
                    // Update the odd in the fixture group
                    state.oddsByFixture[fixtureId][fixtureOddIndex] = newOdd;
                } else {
                    // Add the new odd to the fixture group
                    state.oddsByFixture[fixtureId].push(newOdd);
                }
            });
        },
        updateWithLiveOdds: (state, action) => {
            const newOdds: Odd[] = [action.payload];

            newOdds.forEach((newOdd) => {
                if (!newOdd.GreenLineId) {
                    return; // Skip the odd if GreenLineId is undefined
                }

                const existingOddIndex = state.odds.findIndex(
                    (odd) =>
                        odd.GreenLineId &&
                        odd.GreenLineId.toString() === newOdd.GreenLineId.toString()
                );

                if (existingOddIndex !== -1) {
                    // Get the existing odd
                    const existingOdd = state.odds[existingOddIndex];

                    // console.log("Existing odd in the store", JSON.stringify(current(state).odds[existingOddIndex], null, 2));

                    // Map through the new odds and add `previousValue` where necessary
                    const updatedOdds = newOdd.Odds.map((newOddDetail) => {
                        const existingOddDetail = existingOdd.Odds.find(
                            (oddDetail) => oddDetail.OddId === newOddDetail.OddId
                        );

                        if (existingOddDetail) {
                            if (existingOddDetail.Odd === newOddDetail.Odd) {
                                return existingOddDetail
                            }
                            else {
                                return { ...existingOddDetail, previousValue: existingOddDetail.Odd, Odd: newOddDetail.Odd }
                            }
                        }
                        else {
                            return { ...newOddDetail }
                        }
                    });

                    // Merge the existing and new odd
                    state.odds[existingOddIndex] = {
                        ...existingOdd,
                        ...newOdd,
                        Odds: updatedOdds, // Replace the Odds array with the updated one
                    };

                    // console.log("Final Store odd", JSON.stringify(current(state).odds[existingOddIndex], null, 2));
                } else {
                    state.odds.push(newOdd);
                }
            });
        },
    }
});

export const { setOdds, updateWithLiveOdds } = oddsSlice.actions;

export default oddsSlice.reducer;
