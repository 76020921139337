import { createSlice } from '@reduxjs/toolkit';

const initialState: { competition_name: string, competition_id:  number } = {
  competition_name :'Premier League',
  competition_id : 8
};

export const CompetitionNameSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    setCompetitionNameAndId: (state, action) => {
      const { competition_name, competition_id } = action.payload;
        state.competition_name = competition_name
        state.competition_id = competition_id
    }
  },
});

export const { setCompetitionNameAndId } = CompetitionNameSlice.actions;

export default CompetitionNameSlice.reducer;